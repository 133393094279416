<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      Yayasan Plan International Indonesia.
      <!-- <b-img
        :src="appLogoImageSecond"
        alt="logo"
        height="20"
      /> -->
      <!-- COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://kitakerja.id"
        target="_blank"
      >#KitaKerja</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span> -->
    </span>

    <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
  </p>
</template>

<script>
import {
  // BLink,
  BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    // BLink,
    // BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageSecond } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      appLogoImageSecond,
    }
  },
}
</script>
