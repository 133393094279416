<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalNew"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalNew }} Baru
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Tampilkan semua notifikasi
        </h6>
        <b-form-checkbox
          :checked="false"
          switch
          @change="showAll()"
        />
      </div>
      <!-- Account Notification -->
      <b-link
        v-for="notification in notificationList"
        :key="notification.id"
        @click="readNotification(notification.id, notification.url)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">
            <small class="float-right mb-25">
              {{ formatDateToMonthShort(notification.created_at, { hour: 'numeric', minute: 'numeric' }) }}
            </small>
            {{ notification.subtitle }}
          </small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"
      v-if="total"
    >
      <b-pagination
        v-model="currentPage"
        align="center"
        :total-rows="total"
        :per-page="perPage"
        @change="handlePageChange"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BPagination,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import {
  getAllNotification,
  readNotification,
} from '@/api/notification'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BPagination,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      search_query: '',
      notificationList: [],
      currentPage: 1,
      perPage: 6,
      total: 0,
      totalNew: 0,
      showAllData: 0,
      /* eslint-disable global-require */
      defaultImage: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
    }
  },
  mounted() {
    this.getDataPage()
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData && userData.id) {
      window.Echo.channel(`Notification.${userData.id}`)
        .listen('SendNotification', event => {
          if (event.user_id !== this.userData.id) {
            this.notificationList.push(event)
          }
        })
    }
  },
  methods: {
    async readNotification(id, url) {
      await readNotification(id)
        .then(response => {
          this.getDataPage()
          this.$router.push(url)
        })
    },
    showAll() {
      if (this.showAllData === 1) {
        this.showAllData = 0
      } else {
        this.showAllData = 1
      }
      this.getDataPage()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      await getAllNotification(this.currentPage, this.showAllData)
        .then(response => {
          this.notificationList = response.data.data
          this.total = response.data.total
          this.totalNew = response.data.totalNew
        })
      /*
      this.notificationList.data.forEach((value, index) => {
        if (value.img) {
          this.kegiatanList.data[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        } else {
          this.kegiatanList.data[index].img = this.defaultImage
        }
      })
      */
      // this.total = response.data.total
    },
  },
  setup() {
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      formatDateToMonthShort,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
