export default [
  {
    title: 'Proyek',
    route: 'pages-project',
    icon: 'BriefcaseIcon',
    resource: 'proyek',
    action: 'list',
  },
  {
    title: 'Kegiatan',
    route: 'pages-kegiatan',
    icon: 'CalendarIcon',
    resource: 'kegiatan',
    action: 'list',
  },
  // {
  //   title: 'Form Motivasi',
  //   route: 'pages-form',
  //   icon: 'TargetIcon',
  //   resource: 'amres',
  //   action: 'list',
  // },
  // {
  //   title: 'Keterampilan',
  //   route: 'pages-keterampilan',
  //   icon: 'StarIcon',
  //   resource: 'keterampilan',
  //   action: 'list',
  // },
  {
    header: 'Dashboard & Laporan',
    resource: 'pemantauan',
    action: 'list',
  },
  // {
  //   title: 'Log Import User Proyek',
  //   route: 'log-import-list',
  //   icon: 'FileTextIcon',
  //   resource: 'log-import',
  //   action: 'manage',
  // },
  {
    title: 'Pemantauan',
    route: 'pages-dashboard',
    icon: 'PieChartIcon',
    resource: 'pemantauan',
    action: 'list',
  },
  {
    title: 'Pelaporan',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Daftar User Proyek',
        route: 'report-list-proyek',
        resource: 'report-proyek',
        action: 'list',
      },
      {
        title: 'Daftar User Kegiatan',
        route: 'report-list-kegiatan',
        resource: 'report-kegiatan',
        action: 'list',
      },
      {
        title: 'Daftar Kegiatan Projek',
        route: 'laporan-kegiatan',
        resource: 'laporan-kegiatan',
        action: 'list',
      },
    ],
  },
  {
    header: 'Pengaturan',
    resource: 'users',
    action: 'password',
  },
  {
    title: 'Pengguna',
    icon: 'UserIcon',
    children: [
      {
        title: 'Daftar Pengguna',
        route: 'pengguna-list',
        resource: 'pengguna',
        action: 'list',
      },
      {
        title: 'Hak Akses',
        route: 'pengguna-rules-list',
        resource: 'rules',
        action: 'list',
      },
    ],
  },
  {
    title: 'Ubah Kata Sandi',
    route: 'pages-change-password',
    icon: 'LockIcon',
    resource: 'users',
    action: 'password',
  },
  {
    title: 'Ubah Profil',
    route: 'pages-account-setting',
    icon: 'UserIcon',
    resource: 'users',
    action: 'change',
  },
  {
    header: 'Log',
    resource: 'log',
    action: 'list',
  },
  {
    title: 'Log Import',
    route: 'log-import-list',
    icon: 'FileTextIcon',
    resource: 'log-import',
    action: 'manage',
  },
  {
    title: 'Log Mail Reminder',
    route: 'log-mail-list',
    icon: 'FileTextIcon',
    resource: 'log-mail',
    action: 'manage',
  },
  {
    header: 'Master',
    resource: 'master',
    action: 'list',
  },
  {
    title: 'Data Master',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Kategori Keterampilan',
        route: 'kategori-keterampilan',
        icon: 'CircleIcon',
        resource: 'kategori-keterampilan',
        action: 'manage',
      },
      {
        title: 'Keterampilan',
        route: 'kelola-keterampilan',
        icon: 'CircleIcon',
        resource: 'kelola-keterampilan',
        action: 'manage',
      },
      // {
      //   title: 'Kategori FAQ',
      //   route: 'kategori-faq',
      //   icon: 'CircleIcon',
      //   resource: 'kategori-faq',
      //   action: 'manage',
      // },
      // {
      //   title: 'FAQ',
      //   route: 'faq',
      //   icon: 'CircleIcon',
      //   resource: 'faq',
      //   action: 'manage',
      // },
      {
        title: 'Aplikasi',
        route: 'aplikasi',
        icon: 'CircleIcon',
        resource: 'aplikasi',
        action: 'manage',
      },
      {
        title: 'Status Pernikahan',
        route: 'status-pernikahan',
        icon: 'CircleIcon',
        resource: 'status-pernikahan',
        action: 'manage',
      },
      {
        title: 'Status Pekerjaan',
        route: 'status-pekerjaan',
        icon: 'CircleIcon',
        resource: 'status-pekerjaan',
        action: 'manage',
      },
      {
        title: 'Jenis Program',
        route: 'jenis-program',
        icon: 'CircleIcon',
        resource: 'jenis-program',
        action: 'manage',
      },
      {
        title: 'Jenis Disabilitas',
        route: 'jenis-disabilitas',
        icon: 'CircleIcon',
        resource: 'jenis-disabilitas',
        action: 'manage',
      },
      {
        title: 'Jenis Pendidikan',
        route: 'jenis-pendidikan',
        icon: 'CircleIcon',
        resource: 'jenis-pendidikan',
        action: 'manage',
      },
      {
        title: 'Tag',
        route: 'tag',
        icon: 'CircleIcon',
        resource: 'tag',
        action: 'manage',
      },
    ],
  },
  {
    header: 'Layanan Bantuan',
    resource: 'faq',
    action: 'list',
  },
  // {
  //   title: 'FAQ',
  //   route: 'pages-faq',
  //   resource: 'faq',
  //   action: 'list',
  // },
  {
    title: 'Kontak Kami',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
    resource: 'chat',
    action: 'list',
  },
  // {
  //   title: 'Manajemen Aset',
  //   route: 'manajemen-aset-list',
  //   icon: 'MailIcon',
  //   resource: 'manajemen-aset',
  //   action: 'list',
  // },
  // {
  //   header: 'Usulan',
  //   resource: 'permohonan',
  //   action: 'list',
  // },
  // {
  //   title: 'Permohonan',
  //   icon: 'ServerIcon',
  //   children: [
  //     {
  //       title: 'Usulan',
  //       route: 'permohonan-list',
  //       resource: 'permohonan',
  //       action: 'list',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pradesain',
  //   icon: 'CheckSquareIcon',
  //   children: [
  //     {
  //       title: 'Pradesain',
  //       route: 'pradesain-list',
  //       resource: 'pradesain',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Jadwal Asistensi',
  //       route: 'asistensi-list',
  //       resource: 'pradesain-asistensi',
  //       action: 'list',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pelaporan',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'Mingguan',
  //       route: 'report-list-mingguan',
  //       resource: 'report-mingguan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Bulanan',
  //       route: 'report-list-bulanan',
  //       resource: 'report-bulanan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'PHO',
  //       route: 'report-list-pho',
  //       resource: 'report-pho',
  //       action: 'list',
  //     },
  //     {
  //       title: 'FHO',
  //       route: 'report-list-fho',
  //       resource: 'report-fho',
  //       action: 'list',
  //     },
  //   ],
  // },
  // {
  //   header: 'Projek Manajemen',
  //   resource: 'pelaksanaan',
  //   action: 'list',
  // },
  // {
  //   title: 'Pelaksanaan',
  //   route: 'pelaksanaan-list',
  //   icon: 'BoxIcon',
  //   resource: 'pelaksanaan',
  //   action: 'list',
  // },
  // {
  //   title: 'Status Pekerjaan',
  //   route: 'pelaksanaan-list-kanban',
  //   icon: 'GridIcon',
  //   resource: 'pelaksanaan-kanban',
  //   action: 'list',
  // },
  // {
  //   title: 'Kalendar Projek',
  //   route: 'pelaksanaan-list-calendar',
  //   icon: 'CalendarIcon',
  //   resource: 'pelaksanaan-calendar',
  //   action: 'list',
  // },
  // {
  //   header: 'Pengaturan',
  // },
  // {
  //   title: 'Pengguna',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Daftar Pengguna',
  //       route: 'pengguna-list',
  //       resource: 'pengguna',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Hak Akses',
  //       route: 'pengguna-rules-list',
  //       resource: 'rules',
  //       action: 'list',
  //     },
  //   ],
  // },
  // {
  //   title: 'Master Data',
  //   icon: 'LayoutIcon',
  //   children: [
  //     {
  //       title: 'Kategori Aset',
  //       route: 'master-kategori-aset-list',
  //       resource: 'master-kategori-aset',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Status Aset',
  //       route: 'master-status-aset-list',
  //       resource: 'master-status-aset',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Kondisi Aset',
  //       route: 'master-kondisi-aset-list',
  //       resource: 'master-kondisi-aset',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Wilayah',
  //       route: 'master-wilayah-list',
  //       resource: 'master-wilayah',
  //       action: 'list',
  //     },
  //     {
  //       title: 'UPT Pemasyarakatan',
  //       route: 'master-upt-list',
  //       resource: 'master-upt',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Jabatan',
  //       route: 'master-jabatan-list',
  //       resource: 'master-jabatan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Status Usulan',
  //       route: 'master-status-permohonan-list',
  //       resource: 'master-status-permohonan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Jenis Kegiatan',
  //       route: 'master-jenis-kegiatan-list',
  //       resource: 'master-jenis-kegiatan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Status Pelaksanaan',
  //       route: 'master-status-pelaksanaan-list',
  //       resource: 'master-status-pelaksanaan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Kategori Laporan',
  //       route: 'master-kategori-laporan-list',
  //       resource: 'master-kategori-laporan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Persyaratan',
  //       route: 'master-persyaratan-list',
  //       resource: 'master-persyaratan',
  //       action: 'list',
  //     },
  //     {
  //       title: 'Jenis Dokumen',
  //       route: 'master-jenis-dokumen-list',
  //       resource: 'master-jenis-dokumen',
  //       action: 'list',
  //     },
  //   ],
  // },
  // {
  //   header: 'Bantuan',
  // },
  // {
  //   title: 'Penggunaan SiMBA',
  //   route: 'penggunaan',
  //   icon: 'FolderIcon',
  // },
  // {
  //   title: 'Live Chat',
  //   route: 'livechat',
  //   icon: 'MessageSquareIcon',
  //   resource: 'livechat',
  //   action: 'list',
  // },
  // {
  //   title: 'Kontak Admin',
  //   route: 'kontak-admin',
  //   icon: 'LifeBuoyIcon',
  //   resource: 'kontak',
  //   action: 'list',
  // },
]
